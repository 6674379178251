import $axios from "../../api";

// MODULE NAME
const PREFIX = "acc/stock";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list:{},
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LIST_LOG(state, payload) {
    state.listLog = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios.get(`${PREFIX}/list?page=${state.page}&view=${rootState.Main.view}${payload || ""}`)
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  transferStock({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${PREFIX}/transfer`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
