import $axios from "../../api";
import FileSaver from "file-saver";

const state = {
  isLoading: false,
  createLoading: false,
  errors: "",
  isSuccess: false,
  userList: [],
  logUser: [],
  userData: {},
  userAccessData: {},
  page: 1,
  ipData: "Please Wait...",
};

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_USER_LIST(state, payload) {
    state.userList = payload;
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload;
  },
  SET_LOG_USER(state, payload) {
    state.logUser = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_CREATE_LOAD(state, payload) {
    state.createLoading = payload;
  },
  SET_USER_ACCESS_DATA(state, payload) {
    state.userAccessData = payload;
  },
  SET_IP_DATA(state, payload) {
    state.ipData = payload;
  }
};

const actions = {
  getUserList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`user/list?page=${state.page}&view=${rootState.Main.view}${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_USER_LIST", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getUserData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`user/view?user_id=${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_USER_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createUserData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`user/create`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateUserData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`user/update`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getLogUser({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`user/log-activity?page=${state.page}&view=${rootState.Main.view}${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_LOG_USER", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getDataByIp({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IP_DATA", "Please Wait...")
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `https://freeipapi.com/api/json/${payload ? payload : ""
          }`
        )
        .then((response) => {
          commit("SET_IP_DATA", response.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  showhide({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`user/showhide`, payload)
        .then((response) => {
          commit("SET_SUCCESS", true);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getUserAccess({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`user/access?user_id=${payload}`)
        .then((response) => {
          commit("SET_USER_ACCESS_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateAccess({ commit, state, dispatch }, payload) {
    commit("SET_CREATE_LOAD", true);
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`user/assign-access`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  exportExcel({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `user/exportexcel?page=${state.page}${payload ? payload : ""
          }`,
          { responseType: "blob" }
        )
        .then((response) => {
          FileSaver.saveAs(
            response.data,
            `Export_Data_User_Query=page=${state.page}${payload ? payload : ""
            }.xlsx`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  exportPdf({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `user/exportpdf?page=${state.page}${payload ? payload : ""
          }`,
          { responseType: "blob" }
        )
        .then((response) => {
          FileSaver.saveAs(
            response.data,
            `Export_Data_User_Query=page=${state.page}${payload ? payload : ""
            }.pdf`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
