import $axios from "../../api";

const state = () => ({
  isLoading: false,
  // isErrors: "",

  barangList:[],
  productList:[],
  customerList:[],
  pabrikList:[],
  warnaList:[],
  warehouseList:[],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  
  SET_BARANG(state, payload) {
    state.barangList = payload;
  },
  SET_PRODUCT(state, payload) {
    state.productList = payload;
  },
  SET_CUSTOMER(state, payload) {
    state.customerList = payload;
  },
  SET_PABRIK(state, payload) {
    state.pabrikList = payload;
  },
  SET_WAREHOUSE(state, payload) {
    state.warehouseList = payload;
  },
  SET_WARNA(state, payload) {
    state.warnaList = payload;
  },
};

const actions = {
  selectBarang({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/barang?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_BARANG", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  selectCustomer({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/customer?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_CUSTOMER", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  selectPabrik({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/pabrik?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_PABRIK", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  selectWarehouse({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/warehouse?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_WAREHOUSE", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  selectProductByWarehouse({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/product-by-warehouse?warehouse_id=${payload ? payload : 0}`)
        .then((res) => {
          commit("SET_PRODUCT", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
  selectWarna({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`acc/select/warna?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_WARNA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
